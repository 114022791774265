<template>
  <v-container id="subscribe" tag="section">
    <base-card color="rgb(249, 249, 249)" class="pa-3">
      <v-container class="container">
        <v-row>
          <v-col v-if="!didSubscribe" cols="12" md="5">
            <base-subheading class="mb-3"> Subscribe </base-subheading>

            <p>
              Subscribe to my exclusive monthly newsletter and dive into a world
              of insightful real estate tips, discover my top restaurant picks,
              financial tips and much more.
            </p>

            <v-row class="pa-2">
              <v-responsive class="overflow-visible mr-2" max-width="400">
                <v-text-field
                  v-model="firstName"
                  hide-details
                  label="Your First Name"
                  solo
                />
              </v-responsive>
            </v-row>

            <v-row class="pa-2">
              <v-responsive class="overflow-visible mr-2" max-width="400">
                <v-text-field
                  v-model="lastName"
                  hide-details
                  label="Your Last Name"
                  solo
                />
              </v-responsive>
            </v-row>

            <v-row class="pa-2">
              <v-responsive class="overflow-visible mr-2" max-width="400">
                <v-text-field
                  v-model="email"
                  hide-details
                  label="Your Email Address"
                  solo
                />
              </v-responsive>
            </v-row>

            <div class="errorMessage">
              <p>{{ errorMessage }}</p>
            </div>

            <v-row class="pa-2">
              <v-btn
                :block="$vuetify.breakpoint.xsOnly"
                class="ma-0"
                color="secondary"
                style="height: 48px"
                @click="subscribe(firstName, lastName, email)"
              >
                Subscribe
              </v-btn>
            </v-row>
          </v-col>

          <v-col v-if="didSubscribe" cols="12" md="5">
            <base-subheading class="mb-3"> Thank you </base-subheading>

            <p>You have successfully subscribed to my newsletter.</p>
          </v-col>

          <v-col cols="12" md="6" offset-md="1">
            <base-subheading>Contact</base-subheading>

            <v-list class="transparent">
              <v-list-item>
                <v-list-item-avatar color="primary">
                  <v-icon class="elevation-4" dark> mdi-email-outline </v-icon>
                </v-list-item-avatar>

                <v-list-item-title>
                  mahsa.sa.aghamiri@gmail.com
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar color="primary">
                  <v-icon class="elevation-4" dark> mdi-instagram </v-icon>
                </v-list-item-avatar>

                <v-list-item-title>@itsmahsamiri</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </base-card>
  </v-container>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";

export default {
  name: "HomeSubscribe",

  data: () => ({
    database: firebase.database(),
    didSubscribe: false,
    regularExpression:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    errorMessage: "",
    firstName: "",
    lastName: "",
    email: "",
  }),

  methods: {
    subscribe: function (firstName, lastName, email) {
      if (firstName === null || firstName === "") {
        this.errorMessage = "Please enter your first name";
        return;
      }
      if (lastName === null || lastName === "") {
        this.errorMessage = "Please enter your last name";
        return;
      }

      if (email === null || email === "") {
        this.errorMessage = "Please enter an email address";
        return;
      } else if (!this.regularExpression.test(email)) {
        this.errorMessage = "Please enter a valid email address";
        return;
      }

      this.database
        .ref("users/")
        .push({
          firstName: firstName,
          lastName: lastName,
          email: email,
          subscribedToMailingList: true,
        })
        .then(() => {
          this.didSubscribe = true;
        });
    },
  },
};
</script>

<style lang="scss">
.errorMessage {
  color: red;
}

.container {
  max-width: 100%;
}
</style>
